import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { IconButton, TextField } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { BaseColor, FontFamily, FontWeight } from '../../config';

const CssTextField = withStyles({
    root: {
        '& label': {
            color: BaseColor.inputLableColor,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.regular,
            letterSpacing: '0.5px'
        },
        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px'
        },
        '& label.Mui-focused': {
            color: BaseColor.darkblue,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px'
            }
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: BaseColor.inputBorderColor,
                color: "red"
            },
            '&:hover fieldset': {
                borderWidth: '2px',
                borderColor: BaseColor.navLinkColor
            },
            '&.Mui-focused fieldset': {
                borderColor: BaseColor.darkblue
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 15px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.inputLableColor,
                fontWeight: FontWeight.regular
            }
        }
    }
})(TextField);
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    TextFieldStyle: {
        width: "100%",
        fontSize: 15,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.bold,
        borderRadius: '8px'
    }
}));

const CustomInputComponent = props => {
    const { value, name, onChange, error, formvalue, setFormValue, lable, } = props
    const [phoneNumber, setPhoneNumber] = useState(value);

    const classes = useStyles();
    // const handleClear = (e) => {
    //     setFormValue({ ...formvalue, [name]: '' })
    // }

    const handleKeyPress = (event) => {
        // Clear one character at a time when backspace key is pressed
        if (event.key === 'Backspace') {
            setFormValue({ ...formvalue, [name]: '' })

        }
    };
    //   useEffect(()=>{
    //       setFormValue({...formvalue,[name]:phoneNumber});
    //   },[phoneNumber])
    return (
        <div className={classes.root}>
            <CssTextField
                error={error}
                className='fromcantrol-input'
                label={lable}
                name={name}
                onKeyDownCapture={handleKeyPress}
                variant="outlined"
                value={phoneNumber}
                onChange={onChange}
             
                {...props} />
        </div>

    )
}

CustomInputComponent.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.bool,
};

CustomInputComponent.defaultProps = {
    style: {},
    maxLength: 15000,
    value: '',
    name: '',
    label: '',
    variant: 'outlined',
    onChange: () => { },
    required: false,
    error: false,
}
export default CustomInputComponent;