import React from 'react';
import Styles from './PageSubTitleStyle'
import PropTypes from "prop-types";
import { IconButton, styled } from '@material-ui/core';
import { Button, Text } from "../../components";
import { Images } from "../../config";
import './Pagesubtitle.css'
import Uploadfile from '../../components/Uploadfile/Uploadfile';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});

const PageSubTitle = (props) => {
    const { RightButtonText, LeftDataClick, isRightButton1, disabled, isRightButton2, RightButtonClick1, RightButtonClick2, RightButtonText1, RightButtonText2, isCenterButtonShow, isRightButtonShow, LeftData, CenterRightClick, RightButtonClick, CenterLeftClick, CenterContentText, isborderShow, isUploadButton, onUploadChange, Uploadaccept } = props
    return (
        <div style={isborderShow ? Styles.pageSubtitleborderbotttom : Styles.pageSubtitleMain}>
            <div style={{ ...Styles.startsection }} className='pagetitle-left-header'>
                {LeftData.map((item, index) => {
                    return (
                        item.ShowTutorial &&
                        <div key={item.id} onClick={() => LeftDataClick(item, index)} style={item.isDrop ? { ...Styles.cursorPointer, ...Styles.flexSectionAlignment } : { ...Styles.flexSectionAlignment, }}>
                            <img style={Styles.Icons} src={item.icon} alt="edit" />
                            <Text grayColor subhead medium >{item.text}</Text>
                            {item.isDrop && <img style={{ paddingTop: "3px" }} src={Images.downarrow} alt="edit" />}
                        </div>
                    )
                })}
            </div>
            {isCenterButtonShow && <div style={{ ...Styles.middlesection }} className='pagetitle-center-header'>
                <IconButton style={Styles.IconButtonPadding} onClick={CenterLeftClick}><img src={Images.LeftArrowGrey} alt="edit" /></IconButton>
                <Text title3 bold grayColor >{CenterContentText}</Text>
                <IconButton style={Styles.IconButtonPadding} onClick={CenterRightClick}><img src={Images.RightArrowGray} alt="edit" /></IconButton>
            </div>}
            <div style={{ ...Styles.endsection }} className='pagetitle-right-header'>
                {isRightButtonShow && <>
                    {isRightButton1 && <div style={Styles.subtitlebutton1}><Button type={3} onClick={RightButtonClick1} round Medium title={RightButtonText1} /></div>}
                    {isRightButton2 && <div style={Styles.subtitlebutton2}><Button disabled={disabled} type={3} onClick={RightButtonClick2} round Medium title={RightButtonText2} /></div>}
                    {isUploadButton && <div style={Styles.subtitlebutton2}> <Uploadfile onChange={onUploadChange} round type={3} title="Upload" accept={Uploadaccept} /> </div>}
                </>}
            </div>
        </div>
    );
}
PageSubTitle.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    subTitle: PropTypes.string,
    title: PropTypes.string,
    CenterContentText: PropTypes.string,
    RightButtonText: PropTypes.string,
    isborderShow: PropTypes.bool,
    isCenterButtonShow: PropTypes.bool,
    isRightButtonShow: PropTypes.bool,
    isRightButton1: PropTypes.bool,
    isRightButton2: PropTypes.bool,
    onClick: PropTypes.func,
    CenterLeftClick: PropTypes.func,
    CenterRightClick: PropTypes.func,
    RightButtonClick: PropTypes.func,
    RightButtonClick1: PropTypes.func,
    RightButtonClick2: PropTypes.func,
    LeftDataClick: PropTypes.func,
    LeftData: PropTypes.array,
};

PageSubTitle.defaultProps = {
    style: {},
    subTitle: '',
    CenterContentText1: '',
    RightButtonText1: '',

    RightButtonText2: '',
    isborderShow: true,
    isCenterButtonShow: true,
    isRightButtonShow: true,
    isRightButton1: true,
    isRightButton2: true,
    LeftData: [{
        icon: Images.Bulb,
        text: 'Tutorial',
        isDrop: false,
        ShowTutorial: true
    },
    {
        icon: Images.LayerIcon,
        text: 'Campain Add',
        isDrop: false,
        ShowTutorial: false
    },
    {
        icon: Images.Viewicon,
        text: 'Art Work Info',
        isDrop: true,
        ShowTutorial: false

    }],
    onClick: () => { },
    CenterLeftClick: () => { },
    CenterRightClick: () => { },
    RightButtonClick1: () => { },
    RightButtonClick2: () => { },
    LeftDataClick: () => { },

}

export default PageSubTitle;