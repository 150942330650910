import React, { useState } from 'react'
import "./SingaleCheckBox.css"

export default function SingaleCheckBox(props) {
    const { type, label, name, checked, id, onChange, defaultValue, isDisabled } = props
    const [isChecked, setIsChecked] = useState(defaultValue);


    return (
        <div className='custom-checkbox'>
            <input
                disabled={isDisabled ? true : false}
                id={id}
                type="checkbox"
                name={name}
                defaultChecked={checked}
                checked={checked}
                onChange={onChange}
            />
            <label htmlFor={`${id}`} style={checked ? { fontWeight: 600 } : { fontWeight: 400 }}>
                {label}
            </label>
        </div>
    )
}

