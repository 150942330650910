import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import PropTypes from "prop-types";
import { BaseColor, FontFamily, FontWeight } from '../../config';
import ClearIcon from '@material-ui/icons/Clear';
import { IconButton } from '@material-ui/core';

const CssTextField = withStyles({
    root: {
        '& label': {
            color: BaseColor.inputLableColor,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.regular,
            letterSpacing: '0.5px'
        },
        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px'
        },
        '& label.Mui-focused': {
            color: BaseColor.darkblue,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px'
            }
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: BaseColor.inputBorderColor,
                color: "red"
            },
            '&:hover fieldset': {
                borderWidth: '2px',
                borderColor: BaseColor.darkblue
            },
            '&.Mui-focused fieldset': {
                borderColor: BaseColor.darkblue
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 15px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.inputLableColor,
                fontWeight: FontWeight.regular
            }
        }
    }
})(TextField);
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    TextFieldStyle: {
        width: "100%",
        fontSize: 15,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.heavy,
        borderRadius: '8px',
        backgroundColor: BaseColor.inputBgColor
    }
}));

const CustomInputComponent = props => {
    const { maxLength, label, value, shrink, disabled, required, handleClear} = props
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssTextField
                InputProps={{
                    maxLength: maxLength,
                    endAdornment: handleClear&&(
                        <IconButton onClick={handleClear} edge="end">
                            <ClearIcon />
                        </IconButton>
                    ),
                }}
                InputLabelProps={{ shrink: shrink }}
                label={required == true ? `${label}*` : label}
                required={required ? true : false}
                fullWidth
                className={classes.TextFieldStyle}
                value={value}
                disabled={disabled}
                variant="outlined"
                {...props} />
        </div>
    )
}
CustomInputComponent.propTypes = {
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChange: PropTypes.func,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    maxLength: PropTypes.number,
    required: PropTypes.bool,
    error: PropTypes.bool,
};

CustomInputComponent.defaultProps = {
    style: {},
    maxLength: 15000,
    value: '',
    name: '',
    label: '',
    variant: 'outlined',
    onChange: () => { },
    required: false,
    error: false,
}

export default CustomInputComponent;