import React, { useEffect, useState } from 'react'
import '../CheckBox/checkbox.css'

export default function CheckBox(props) {
    const { value, name, error, formEdit, formvalue, setFormValue, lable } = props
    // Create an initial state object based on elements' isChecked property
    const elements = value;
    const initialState = value.reduce((acc, curr, index) => {
        acc[`checkbox${index + 1}`] = curr.IsChecked == 1 ? true : false;
        return acc;
    }, {});
    const [checkedItems, setCheckedItems] = useState(initialState);



    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setCheckedItems({
            ...checkedItems,
            [name]: checked
        });
    };

    const checkedElements = elements.filter((element, index) =>
        checkedItems[`checkbox${index + 1}`]
    );
    useEffect(() => {


        const margearry = checkedElements.map((item) => {
            return ({ ...item, IsChecked: 1 })
        })
        // const editArray = checkedElements.map()
        setFormValue({ ...formvalue, [name]: margearry })

    }, [checkedItems])
    return (
        <div>
            <label className='checkbox-lable' htmlFor="checkbox-main">{lable}</label>
                <div className='checkbox-design'>
            {elements.map((element, index) => (
                    <div key={index} id='checkbox-main' className='checkbox-wrapper-4'>
                        <input
                            className='inp-cbx'
                            type="checkbox"
                            id={`checkbox${index + 1}`}
                            name={`checkbox${index + 1}`}
                            checked={checkedItems[`checkbox${index + 1}`]}
                            onChange={handleCheckboxChange}
                        />

                        <label className='check-lable cbx' htmlFor={`checkbox${index + 1}`}>
                            <span>
                                <svg width="12px" height="10px">
                                    <use href="#check-4"></use>
                                </svg></span><span>{element.Name}</span></label>
                        <svg className="inline-svg">
                            <symbol id="check-4" viewbox="0 0 12 10">
                                <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                            </symbol>
                        </svg>
                    </div>
            ))}
                </div>
            {error && <div className='chackbox-error-line'>
                Please select atleast one Checkbox
            </div>}
        </div>
    )
}
