import PropTypes from "prop-types";
import React, { useEffect, useRef } from 'react';
import { URLs } from '../../API/URLs';
import { TextField } from "../../components";

const PlacesAutocompleteTextField = (props) => {
  const { label, style, name, required, variant, error, value, onChange, selectedPlaced } = props
  const autocompleteRef = useRef(null);
  const inputRef = useRef(null);

  useEffect(() => {
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=${URLs.googlekey}&libraries=places`;
      script.onload = () => initAutocomplete();
      document.body.appendChild(script);
    };

    if (!window.google) {
      loadGoogleScript();
    } else {
      initAutocomplete();
    }

    function initAutocomplete() {
      autocompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        { types: ['address'], componentRestrictions: { country: 'us' } }
      );

      autocompleteRef.current.addListener('place_changed', onPlaceChanged);
    }

    function onPlaceChanged() {
      const place = autocompleteRef.current.getPlace();
      let data = getCityAndPostalCode(place)
      selectedPlaced(data)
    }
  }, []);

  function getCityAndPostalCode(place) {
    let city = '';
    let postalCode = '';
    let addressLine1 = '';
    let addressLine2 = '';
    let country = ''
    let state = ''
    let latitude = ''
    let longitude = ''
    let fullAddress = ''


    if (place.address_components) {
      place.address_components.forEach(component => {
        if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        } else if (component.types.includes("country")) {
          country = component.long_name;
        } else if (component.types.includes("administrative_area_level_1")) {
          state = component.long_name;
        } else if (component.types.includes("street_number") || component.types.includes("route")) {
          addressLine1 = addressLine1 + component.long_name;
        } else if (component.types.includes("subpremise")) {
          addressLine2 = component.long_name;
        }
      });

      latitude = place.geometry.location.lat()
      longitude = place.geometry.location.lng()
      fullAddress = place.formatted_address

    }

    return { city, postalCode, fullAddress, addressLine1, addressLine2, state, country, latitude, longitude };
  }

  return (
    <TextField
      style={style}
      label={label}
      name={name}
      value={value}
      variant={variant}
      selectedPlaced={selectedPlaced}
      onChange={onChange}
      required={required}
      error={error}
      inputRef={inputRef}
    />
  );
};
PlacesAutocompleteTextField.propTypes = {
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onChange: PropTypes.func,
  selectedPlaced: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  variant: PropTypes.string,
  maxLength: PropTypes.number,
  required: PropTypes.bool,
  error: PropTypes.bool,
};

PlacesAutocompleteTextField.defaultProps = {
  style: {},
  maxLength: 15000,
  value: '',
  name: '',
  variant: 'outlined',
  onChange: () => { },
  selectedPlaced: () => { },
  required: false,
  error: false,
}
export default PlacesAutocompleteTextField;
