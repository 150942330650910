
import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { Modal, Text } from '../../components';

function Loader() {
    return (
        <Modal show={true} IsTrasparent IsCloseIcon={false}>
            <div style={{ width: '100wh', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={50} color={'red'} />
                <Text header style={{ marginLeft: '20px' }}> Loading...</Text>
            </div>
        </Modal>
    )
}

export default (Loader);