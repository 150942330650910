import moment from "moment";
import { URLs } from "../API/URLs";

var CryptoJS = require("crypto-js");

const defaultDateFormats = {
  DDMMYYYY: 'DD/MM/YYYY',
  MMDDYYYY: 'MM/DD/YYYY'
}

const Constanttitle = {
  name: 'Airex',
  desc: 'Airex - React.js',

  Title1: "WELCOME TO AIREX",
  Title2: "A RUBBER PRODUCTS",
  OrderDetails: "Order Details",
  ProductCost: "Product Cost",
  Producttitle: "Email Marketing:",
  ProductSubtitle: "Customize, Copy, Send.",
  Ordertitle: "My Orders.",
  Ordersubtitle: "View Details.",
  CustomPromotitle: "Custom Promo Pages.",
  CustomPromosubtitle: "Edit and Manage",
  LocalAdtitle: "Local Ad Campaigns.",
  LocalAdsubtitle: "Customized for Your Practice.",
  Producttitle: "Email Marketing:",
  Hometitle: "WELCOME TO REACH",
  Homeleft: "Practice Marketing Service. ",
  HomeRight: "Targeted Campaigns for Maximum Engagement.",
  HomeDownLeft: " Practice Support Assets.",
  HomeDownright: " Elevate Efficiency, Enhance Practice Impact.",
  Featuredtitle: "Select All: set Featured Pratice Info For Your Campaign(s)",
  Featuredlefttitle: "Featured Pratice Logo",
  PracticeLogo: "Practice Logo",
  Practicetitle: "Practice Information",
  UserProfile: "User Profile",
  Annualsupplytitle: "Annual Supply Calculator Profile",
  Annualsupplyrighttext: "Enter your standard pricing for each BAUSCH+Lomb lens brand and indication.Click <b className='annualsupply-bold' >CONFIRM.</b> Prices willauto-populate in the Annual Supply Cost Calculator on the top menu.You may update pricing anytime.",
  Annualsupplycalculatertitle: "Annual Supply Calculator.",
  Annualsupplycalculaterheading: "Here`s What Your Bausch + Lomb Contact Lenses Will Cost",
  Dirctmailtitle: "Direct Mail & Print.",
  Dirctmailsubtitle: "Customize, Print, or Download.",
  Changepassword: "Change Password",
  Mediatitle: "Media Library.",
  Mediasubtitle: "Upload, Favorite, and Select from Stock images.",
  Cart: "Your Cart",
  CopiedClipboard: "Content Copied to Clipboard",
  PrivacyPolicysubtitle: "COBRAND MEDIA ONLINE TERMS AND CONDITIONS",
  Practiceheading: "PRACTICE REGISTRATION FORM",
  Practicesubheading: "Enter your practice info below to be setup in the Bausch + Lomb REACH Marketing Portal",
  Practicesection2heading: "The practice information cannot be verified. Please enter your practice information below. Practices will receive login credentials to the REACH Portal via email upon verification by Bausch + Lomb.",
  clinicregistrationcardtitle1: "Practice Info",
  clinicregistrationcardtitle2: "Brands To Included In The Bausch + Lomb Doc Finder",
  clinicregistrationcardtitle3: "Additional Info",
  clinicregistrationcardtitle4: "Practice Logo",
  VsMember: "Vision Sources Member Practice",
  VSPMember: "VSP Premier Member Practice",
  registrationaddanotherlocation: "Do you want to add another location to this account?",
  registrationtrems: "Please confirm that you agree to the terms & conditions.",
  registrationpracticetext: "• Accepted files: PNG & JPG • Recommended logo size: 1050 pixels X 750 pixels • Smaller logos may appear pixelated in print products",
  addsocialpostAI1: "To utilize our AI tool for composing your social media posts, simply provide a description of the content you desire here",
  addsocialpostAI2: "Alternatively, you can directly input your desired post copy into the designated 'Post Copy' text box to the left. Please ensure clarity and specificity in your description for optimal AI-generated results.",
  addsocialpostNote: "Note: For Bausch + Lomb posts,please use content from our library. Competitive brand posts may not be added to your celender.",
  addsocialpostaddpost: "Select your social platform(s), post date and time then click CONFIRM to add your post to the calendar.",
  featurepracticefirstparagraph: "Enter the featured location information you'd like to showcase in your marketing campaign(s). Opting include ALL locations trigger the automatic generation of a custom doc finder page. This doc finder function as a centralized promotional site directly linked to your campaign(s), seamlessly connecting consumers to your practices and offers.",
  featurepracticeverifytext: "Verify your facebook and/or Instagram credentials below for REACH to post approved Bausch + Lomb content on your social bussiness pages. Credential verification is done directly with Facebook and Instagram; REACH does not store or access these credentials.",
  CustomPostFeedPagetitle: "Custom Post Feeds",
  Artworkinfopagetitle: "ARTWORK INFO",
  CampainOptiontitle: "Campaign Options",
}


const NumberValidation = (event) => {
  const input = event.target.value;
  // Remove non-numeric characters
  const cleanedInput = input.replace(/\D/g, '');
  // Limit input to 10 digits

  let formattedPhoneNumber = '';
  if (cleanedInput.length <= 10) {
    formattedPhoneNumber = cleanedInput.replace(
      /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
      '($1) $2-$3'
    );
  } else {
    formattedPhoneNumber = cleanedInput.slice(0, 10).replace(
      /^(\d{0,3})(\d{0,3})(\d{0,4})$/,
      '($1) $2-$3'
    );
  }
  return formattedPhoneNumber
};

const NumberDigitValidation = (event, digit) => {
  const input = event.target.value;
  // Remove non-numeric characters
  // const cleanedInput = input.replace(/\D/g, '');
  const cleanedInput = input.replace(/[^\d.]/g, '');
  // Limit input to 10 digits

  const truncatedInput = cleanedInput.slice(0, digit);
  return truncatedInput==undefined?'':truncatedInput
};

const SearchSuggestions = (value, suggestionData) => {
  const mockSuggestions = suggestionData

  // Filter suggestions based on the input value
  const filteredSuggestions = mockSuggestions.filter(suggestion =>
    suggestion.toLowerCase().includes(value.toLowerCase())
  );

  return filteredSuggestions;
};


const AppConstant = {
  StickyColor: '#fff',
  Pagination: [10, 25, 50, 100, 200, 500, 1000],
  DefaultSort: 'DESC',
  DefaultRecordsPerPage: 25,
}

const chipData = [
  { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': "NO", name: "NO", width: '60px' },
  { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': "YES", name: 'YES', width: '60px' },
  { 'color': "#1a1110c7", 'backgroundColor': '', 'value': "WorkOrders", name: 'WorkOrders', width: '60px' },
  { 'color': "#20b756", 'backgroundColor': '#12df2942', 'value': '1', name: 'Created', width: '100%' },
  { 'color': "#d582a4", 'backgroundColor': '#df12ac4a', 'value': '2', name: 'Acknowledged', width: '100%' },
  { 'color': "#0a34b1b8", 'backgroundColor': '#1237df4a', 'value': '3', name: 'Assigned', width: '100%' },
  { 'color': "#6D225C", 'backgroundColor': '#DD92CB', 'value': '4', name: 'Started', width: '100%' },
  { 'color': "#3c763d", 'backgroundColor': '#dff0d8', 'value': '5', name: 'Completed', width: '100%' },
  { 'color': "#bf762a", 'backgroundColor': '#ffa2337a', 'value': '6', name: 'Closed', width: '100%' },
  { 'color': "#a94442", 'backgroundColor': '#f2dede', 'value': '7', name: 'Cancelled', width: '100%' },
  { 'color': "black", 'backgroundColor': '#80808099', 'value': "" }]

const IsEmpty = (data) => {
  return (data == null || data.length === 0 || data == undefined || data == '');
}

const isEmptyObject = (data) => {
  return (Object.keys(data).length === 0 && data.constructor === Object)
}

// Navigate, Customize 1
// Preview popup 2

const GetButtons = (ID) => {
  switch (ID) {
    case 1:
    case 7:
    case 8:
    case 9:
    case 10:
      return [{ Name: 'View', Action: 2 }]
    case 2:
      return [{ Name: 'Order', Action: 1 }, { Name: 'Preview', Action: 2 }]
    case 3:
      return [{ Name: 'Download', Action: 5 }, { Name: 'Copy Text', Action: 6 }]
    case 4:
    case 5:
      return [{ Name: 'Customize', Action: 1 }, { Name: 'Preview', Action: 2 }]
    case 6:
      return [{ Name: 'View Post', Action: 3 }, { Name: '+ Calendar', Action: 4 }]
    default:
      return []
  }

}

const ProductScreenNavigate = (ModuleNo) => {
  switch (ModuleNo) {
    case 1:
      return "/location";
    case 3:
      return "/my-landing-page"
    case 2:
    case 4:
    case 5:
      return "/LocalAd"
    default:
      return '/homescreen';
  }
}

// Social Media Ads	1
// Display Ads	2
// Doc Finder	3
// Promo Page	4
// Free Trial	5
// Email Marketing	6
// Postcard Front	7
// Postcard Back	8
// Social Automation	9
// Free Assets	10

let FormatDate = 'DD-MMM-YY'

function GetDateWithMyFormat(date) {
  return moment(date).format(FormatDate)
}
let FormatDateCMMS = 'DD/MM/YYYY'

function GetCMMSDateWithMyFormat(date) {
  return moment(date).format(FormatDateCMMS)
}

const DOCUMENTS = [
  {
    title: 'Upload Front Image',
    SequenceNo: 0,
    DocumentName: '',
    DocumentData: '',
    DocumentURL: '',
    Sync: 0,
  }
]
const FileExtension = {
  TypeCSV: '.csv',
  TypeSVG: '.svg',
  TypeZIP: '.zip',
  TypeHTML: '.html',
  TypeMP4: '.mp4',
  TypePNG: '.png',
  TypeJPG: '.jpg',
  TypePDF: '.pdf',
}

const TableColor = { main: '#114d8b', buttonFontColor: "#ffff" }

const DocumentTypes = {
  Category: 1,
  ProductTile: 2,
  ProductTileHover: 3,
  ProductDescription: 4,
  ProductImages: 5,
  ProductAssets: 6,
  ProductAssetPreview: 7,
  ProductSubCategoryAsset: 8,
  ProductSubCategoryAssetPreview: 9,
  LocationLogo: 10,
  LocationPhoto: 11,
  CalendarAsset: 12,
  CustomAsset: 13,
  WelcomeScreenPopup: 14,
  ScreenBanner: 15,
  OrderDetailsLogo: 16,
  SupplyToolBanner: 17,
  SupplyToolFooter: 18,
  SupplyToolLead: 19,

  FaviconIcon: 1,
  LoginHeaderLogo: 2,
  LoginScreenImage: 3,
  LoginFooterLogo: 4,
  LoginHeaderBar: 5,
  HeaderLogo: 6,
  FooterLogo: 7,

  CalendarAssetPreview: 26,
  MediaLibrary: 27,
}

const facebookConfig = {
  client_id: "584550876158294", // Live
  fields: 'email,picture',
  fbscope: 'public_profile,pages_show_list,pages_manage_posts',
  instascope: 'public_profile,instagram_basic,instagram_content_publish'
}



const GetLatLongFromAddress = (address) => {
  const apiKey = URLs.googlekey; // Replace with your actual API key
  const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
  let latitude = '';
  let longitude = '';
  fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        latitude = location.lat;
        longitude = location.lng;
        return { latitude, longitude }
      } else {
        console.error("Error:", data.status);
      }
    })
    .catch(error => {
      console.error("Error fetching data:", error);
    });
}

const DemoCoords = [
  { lat: 29.047487, lng: 41.023164 },
  { lat: 29.0459633, lng: 41.0212904 },
  { lat: 29.0449333, lng: 41.0167573 },
  { lat: 29.0393543, lng: 41.0106695 },
  { lat: 29.032917, lng: 41.0049697 },
  { lat: 29.0226173, lng: 41.0061356 },
  { lat: 29.0078545, lng: 41.0039334 },
  { lat: 29.0201283, lng: 40.9765933 },
  { lat: 29.0319729, lng: 40.9657708 },
  { lat: 29.0784073, lng: 40.9536501 },
  { lat: 29.0944576, lng: 40.9493068 },
  { lat: 29.0975475, lng: 40.9514461 },
  { lat: 29.1052294, lng: 40.9647986 },
  { lat: 29.097338, lng: 40.978242 },
  { lat: 29.0931273, lng: 40.9835914 },
  { lat: 29.0858746, lng: 40.987738 },
  { lat: 29.056509, lng: 40.998902 },
  { lat: 29.061456, lng: 41.008443 },
  { lat: 29.0617561, lng: 41.0104752 },
  { lat: 29.0595245, lng: 41.0126772 },
  { lat: 29.052014, lng: 41.018198 },
  { lat: 29.047487, lng: 41.023164 },
]


const secretKey = 'TC2024KN'

const storageKeys = {
  'AccessToken': 'A-T',
  'JWTData': 'JWT-D',
  'IsLoged': 'I-L',
  'MainLogo': 'M-L',
  'UserID': 'U-D',
  'ProductID': 'P-D',
  'RoleID': 'R-D',
  'CategoryID': 'C-D',
  'ProductCategoryID': 'PC-ID',
  'ProductCategorySubCategoryID': 'PCS-ID',
  'TenantConfiguration': 'TEN-C',
}

const addItemStore = (key, value) => {
  const cipherText = CryptoJS.AES.encrypt(value, secretKey).toString()
  let newObj = { key: key, value: cipherText }
  let initialvalue = !IsEmpty(localStorage.getItem('p-all')) ? JSON.parse(localStorage.getItem('p-all')) : []
  if (!IsEmpty(initialvalue)) {
    let filtervalue = initialvalue.filter((item) => { return item.key == key })
    if (!IsEmpty(filtervalue)) {
      const updatedArray = initialvalue.map(item => {
        return item.key == key ? { ...item, value: cipherText } : item
      });
      localStorage.setItem('p-all', JSON.stringify(updatedArray))
    }
    else {
      let updatedValue = [...initialvalue, newObj]
      localStorage.setItem('p-all', JSON.stringify(updatedValue))
    }
  }
  else {
    let updatedValue = [...initialvalue, newObj]
    localStorage.setItem('p-all', JSON.stringify(updatedValue))
  }
}


const updateItemStore = (key) => {
  localStorage.setItem('')
}

const getItemStore = (key) => {
  let data = JSON.parse(localStorage.getItem('p-all'))
  if (!IsEmpty(data)) {
    let filtervalue = data.filter((item) => { return item.key == key })
    return !IsEmpty(filtervalue) ? decryptData(filtervalue[0].value) : null
  }
}

const decryptData = (value) => {
  const bytes = CryptoJS.AES.decrypt(value, secretKey)
  const data = (bytes.toString(CryptoJS.enc.Utf8));
  return data
};


const removeItemStore = (key) => {
  localStorage.removeItem(key)
}
const clearStore = () => {
  localStorage.clear()

}

/**
 * function to check if user is logged in or not
 */
const checkLogin = () => {
  if (sessionStorage.authData) {
    return true;
  } else {
    return false;
  }
};

/**
 * function to set user authentication data
 */
const setAuthData = (data) => {
  const cipherText = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
  sessionStorage.setItem('authData', cipherText.toString());
};

/**
 * function to get user authentication data
 */
const getAuthData = () => {
  const data = sessionStorage.authData;
  if (data) {
    const bytes = CryptoJS.AES.decrypt(data.toString(), secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } else {
    return {};
  }
};

/**
 * function to remove user authentication data
 */
const removeAuthData = () => {
  sessionStorage.removeItem('authData');
};

const authService = {
  checkLogin: checkLogin,
  // getAccessToken: getAccessToken,
  // getUserRole: getUserRole,
  // getUserId: getUserId,
  setAuthData: setAuthData,
  getAuthData: getAuthData,
  removeAuthData: removeAuthData,
};

const ShortName = (data) => {
  if (data.length > 1) {
    let data1 = data ? data.split(' ') : 'A'
    if (data1.length >= 2) {
      let Firt = data1[0][0]
      let Last = data1[1][0]
      return ((Firt != undefined ? Firt : "") + (Last != undefined ? Last : '')).toUpperCase()
    }
    else {
      return 'AB'
    }
  }
  else {
    return 'AB'
  }
}
export { AppConstant, ShortName, Constanttitle, DOCUMENTS, DemoCoords, DocumentTypes, FileExtension, GetButtons, GetCMMSDateWithMyFormat, GetDateWithMyFormat, GetLatLongFromAddress, IsEmpty, NumberDigitValidation, NumberValidation, ProductScreenNavigate, SearchSuggestions, TableColor, addItemStore, chipData, clearStore, defaultDateFormats, facebookConfig, getItemStore, isEmptyObject, removeItemStore, storageKeys, updateItemStore, authService };


