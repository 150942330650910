import { BaseColor } from "../../config";
export default {
  pageSubtitleMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 25px',
    border: '0',
  },
  pageSubtitleborderbotttom: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '0px 54px',
    borderBottom: '2px solid',
    borderBottomColor: BaseColor.grayLightColor,
  },
  Icons: {
    padding: '12px 6px 12px 18px',
    height: 55,
  },
  flexSection: {
    display: 'flex',
    width: '29%',
  },
  LeftflexSection: {
    width: '40%',
    display: 'flex',
    flexWrap: 'wrap',
    marginLeft: '20px'
  },
  flexSectionAlignment: {
    display: 'flex',
    alignItems: 'center',
  },
  IconButtonPadding: {
    padding: 2
  },
  textAlignCenter: {
    textAlign: 'center'
  },
  textAlignRight: {
    textAlign: 'Right'
  },
  JustifyAlignRight: {
    justifyContent: 'flex-end'
  },
  JustifyAlignCenter: {
    justifyContent: 'center'
  },
  JustifyAlignSpaceEvenly: {
    // justifyContent: 'center'
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  subtitlebutton1: {
    marginRight: "20px"
  },
  subtitlebutton2: {
    marginRight: "50px",

  },
  startsection: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    paddingLeft: '20px'

  },
  middlesection: {
    width: '20%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },

  endsection: {
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-end',
  }
}