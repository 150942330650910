import React from 'react';
import { MenuItem, TextField } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight } from '../../config';

const CssDropdown = withStyles({
    root: {
        '& label': {
            color: BaseColor.inputLableColor,
            marginTop: 1,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.regular,
            letterSpacing: '0.5px'

        },

        '& .MuiFormLabel-root': {
            lineHeight: 0.4,
            fontSize: '13.9px'
        },

        '& label.Mui-focused': {
            color: BaseColor.navLinkColor,
            fontFamily: FontFamily.FontFamily_1,
            fontWeight: FontWeight.heavy,

        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
            transform: 'translate(14px, 16px) scale(1)',
            '&.MuiInputLabel-shrink': {
                transform: 'translate(14px, -2px) scale(0.79)',
                fontSize: '15px'
            },

        },

        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '8px',
                borderColor: BaseColor.inputBorderColor,
                color: "red"


            },
            '&:hover fieldset': {
                borderWidth: '2px',
                borderColor: BaseColor.navLinkColor
            },
            '&.Mui-focused fieldset': {
                borderColor: BaseColor.darkblue
            },
            '& .MuiOutlinedInput-input': {
                padding: '14.5px 15px',
                fontFamily: FontFamily.FontFamily_1,
                color: BaseColor.navLinkColor,
                fontWeight: FontWeight.regular
            },
        },
    },
})(TextField);
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',

    },
    TextFieldStyle: {

        width: "100%",
        fontSize: 15,

        fontFamily: FontFamily.FontFamily_1,
        fontWeight: FontWeight.heavy,
        borderRadius: '8px',
        backgroundColor: BaseColor.inputBgColor

    },

}));
const Dropdown = props => {
    const { name, label, value, onBlur, DropdownOption, onChange, error, id, DropdownDefultValue, isDisabled } = props
    const classes = useStyles();
    return (
        <CssDropdown
            disabled={isDisabled ? true : false}
            className='fromcantrol-input'
            error={error ? true : false}
            label={label}
            id={id}
            onBlur={onBlur}
            variant='outlined'
            name={name}
            defaultValue={DropdownOption == undefined ? [] : DropdownOption.filter(item => {

                if (item.IsSelect == true) {
                    return item.ID
                }
            })}
            value={value}
            onChange={onChange}
            select >
            {DropdownOption?.map((val, ind) => {
                return (
                    <MenuItem key={ind} value={val.ID}>{val.Name}</MenuItem>
                )
            }
            )}
        </CssDropdown>
    );
};

export default Dropdown;
