export const Images = {
  mainLogo: require('../../assets/images/logo.png'),
  Loginbackgroung:require('../../assets/images/airexlogin.jpg'),
  FooterLogo:require('../../assets/images/FooterLogo.jpg'),
  modelCloseIcon: require('../../assets/images/gray-close-ic.svg'),
  Instagam: require('../../assets/icons/instagram.png'),
  Fail: require('../../assets/icons/fail.png'),
  eyeicon: require('../../assets/icons/eyeicon.png'),
  info: require('../../assets/icons/info.png'),
  downarrow: require('../../assets/icons/down-arrow.png'),
  cross: require('../../assets/icons/cross.png'),
  NavigateHome: require('../../assets/icons/home-navigation.png'),
  LeftArrowBlue: require('../../assets/icons/left-arrow-blue.png'),
  RightArrowBlue: require('../../assets/icons/right-arrow-blue.png'),
  LayerIcon: require('../../assets/icons/Layericon.png'),
  Downwardarrow: require('../../assets/icons/downwardarrow.png'),
  Viewicon: require('../../assets/icons/view_icon.png'),
  RightArrowGrey: require('../../assets/images/right-arrow-grey.svg'),
  LeftArrowGrey: require('../../assets/images/left-arrow-grey.svg'),
  Bulb: require('../../assets/icons/bulb.png'),
  Profileimg: require('../../assets/images/profile_generic.png'),
  Instagramcoloricon: require('../../assets/icons/instagramcoloricon.png'),
  Facebookcoloricon: require('../../assets/icons/facebookcoloricon.png'),
  Search: require('../../assets/icons/search.png'),
  likeicons: require('../../assets/images/likehomescreen.png'),
  Instagramcoloricon: require('../../assets/icons/instagramcoloricon.png'),
  Facebookcoloricon: require('../../assets/icons/facebookcoloricon.png'),
  LeftArrowGrey: require('../../assets/images/LeftArrowGray.png'),
  RightArrowGray: require('../../assets/images/RightArrowGray.png'),
  NextGrayArrows: require('../../assets/images/NextGrayArrows.png'),
  BackGrayArrows: require('../../assets/images/BackGrayArrows.png'),
  MapIcon: require('../../assets/images/google-maps.png'),
  MapDown: require('../../assets/icons/map-down-arrow.png'),
  googleverifiedicon: require('../../assets/icons/Newicons/google verified icon.png'),
  googleunverified: require('../../assets/icons/Newicons/google unverified.png'),

  pdf: require('../../assets/icons/pdf.png'),
  zip: require('../../assets/icons/zip.png'),
  video: require('../../assets/icons/play.png'),
  csv: require('../../assets/icons/csv.png'),
  html: require('../../assets/icons/files.png'),

}
