import { BaseColor, FontFamily } from "../../config/appConfig";
export default {
    CalenderButton: {
        fontSize: 25,
        borderColor: BaseColor.formcontrolBtnColor,
        border: '0px solid ',
        color: '#000',
        borderRadius: 1,
        opacity: 1,
        padding: 0.8,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 700,
        margin: '5px',
        cursor: 'pointer',
        display: 'flex',
        opacity: 1,
        '&:hover': {
            color: BaseColor.grayColor,
            boxShadow: 5,
        },
    },
    CalenderSelectedButton: {
        fontSize: 15,
        backgroundColor: BaseColor.grayColor,
        color: '#fff',
        borderRadius: (1),
        opacity: 1,
        padding: (0.8),
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 700,
        margin: '5px',
        cursor: 'pointer',

    },
    CalenderTitle: {
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 700,
        fontSize: 22,
    },
    CalenderInnerContent: {
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 500,
        fontSize: 18,
        color: BaseColor.grayColor,
        // width: '40px',
    },
    CalenderCurrentContent: {
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 500,
        padding: 5,
        color: '#fff',
        borderRadius: 80,
        width: '30px',
        textAlign: 'center',
        backgroundColor: BaseColor.grayColor,
        fontSize: 15,
    },
    MiniCalenderCurrentContent: {
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 500,
        padding: 3,
        color: '#fff',
        borderRadius: 80,
        width: '30px',
        textAlign: 'center',
        backgroundColor: BaseColor.grayColor,
        fontSize: 15,
    },
    CalenderContent: {
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 700,
        fontSize: 15,
    },
    tooltipTitle: {
        fontFamily: FontFamily.FontFamily_1,
        // color:BaseColor.grayColor,
        fontWeight: 700,
        fontSize: 18,
        textOverflow: 'ellipsis',
        whiteSpace: "warp",
        overflow: 'hidden',
        dispaly: 'flex',
        alignItems: 'center',
        maxWidth: "240px",
        verticalAlign: 'middle',
    },
    tooltipLeftContent: {
        fontFamily: FontFamily.FontFamily_1,
        // color:BaseColor.grayColor,
        fontWeight: 700,
        fontSize: 13,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        dispaly: 'flex',
        alignItems: 'center',
        maxWidth: "240px",
        verticalAlign: 'middle',
    },
    tooltipRightContent: {
        fontFamily: FontFamily.FontFamily_1,
        // color:BaseColor.grayColor,
        fontWeight: 400,
        fontSize: 13,
        textOverflow: 'ellipsis',

        overflowY: 'auto',
        whiteSpace: 'warp',
        overflow: 'hidden',
        dispaly: 'flex',
        alignItems: 'center',

        maxWidth: "240px",
        verticalAlign: 'middle',
    },
    CalenderContentView: {
        textAlign: 'center',
        borderRadius: 0,
        padding: '4px',
        dispaly: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #D3D3D3',
        borderColor: BaseColor.formcontrolBtnColor,
        backgroundColor: '#fcfcfc',
    },
    CalenderTopTitleContentView: {
        marginBottom: 5,
        borderRadius: 0,
        fontFamily: FontFamily.FontFamily_1,
        fontWeight: 700,
        fontSize: 15,
        dispaly: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: BaseColor.grayColor,
        textTransform: 'uppercase',
        width: '100%',
        textAlign: 'center',
        border: '1px solid #D3D3D3',
        padding: '2px 4px',
        borderColor: BaseColor.formcontrolBtnColor,
    },
    CalenderWorkContent: {
        fontFamily: FontFamily.FontFamily_1,
        fontSize: '0.8rem',
        color: 'gray',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        dispaly: 'flex',
        alignItems: 'center',
        maxWidth: "150px",
        verticalAlign: 'middle',
        textAlign: 'left',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: '#000',
            fontWeight: 500,
            boxShadow: 10,
        },
        marginLeft: '5px',
    },
    LocationIcon: {
        padding: '0.1em 20px 0 0'
        , display: 'inline-block', color: '#009900', fontSize: '16px',
    },
    EquipmentIcon: {
        padding: '0.1em 20px 0 0'
        , display: 'inline-block', color: '#990000', fontSize: '16px',
    },

    CalenderWorkContentforDays: {
        fontFamily: FontFamily.FontFamily_1,
        fontSize: '0.9rem',
        color: 'gray',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        dispaly: 'flex',
        alignItems: 'center',
        verticalAlign: 'middle',
        textAlign: 'left',
        justifyContent: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: '#000',
            fontWeight: 500,
            boxShadow: 10,
            padding: '2px',
        },
        // marginLeft: '5px',
    },
    MoredetailContent: {
        fontFamily: FontFamily.FontFamily_1,
        fontSize: '0.9rem',
        fontWeight: 500,
        color: BaseColor.grayColor,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflowX: 'hidden',
        maxWidth: "150px",
        textAlign: 'left',
        marginLeft: '10px',
        cursor: 'pointer'
    },
    WorkOrderHover: {
        cursor: 'pointer ',
        '&:hover': {
            color: '#000',
            fontWeight: 500,
            boxShadow: 10,
        },
    }
}