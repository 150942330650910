import { makeStyles } from '@material-ui/core/styles';
import { BaseColor, FontFamily, FontWeight, Typography } from "../../config";
const ButtonStyle = makeStyles(theme => ({
  button: {
    height: 38,
    fontFamily: 'Nunito Sans',
    fontSize: '16px',
    lineHeight: 1.6,
    letterSpacing: 0.1,
    // fontWeight: 600,
    boxShadow: 'none'
  },
  default: {
    height: 35,
    borderRadius: 5,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 0,
    textTransform: 'capitalize'
  },
  textDefaultOutline: {
    ...Typography.body1,
    fontFamily: FontFamily.FontFamily_1,
    textTransform: 'capitalize',
    color: BaseColor.blackColor,
    fontWeight: FontWeight.semibold,
  },
  textDefaultContained: {
    ...Typography.body1,
    fontFamily: FontFamily.FontFamily_1,
    color: BaseColor.whiteColor,
    fontSize: 16,
    // fontWeight: FontWeight.medium,
  },
  outline: {
    borderWidth: 1,
  },
  full: {
    width: "100%",
    alignSelf: "auto",
  },
  round: {
    borderRadius: 28,
  },
  extraSmall: {
    height: 25,
    minWidth: 70
  },
  small: {
    height: 30,
    minWidth: 80
  },
  Medium: {
    height: 34,
    minWidth: 100
  },
  Large: {
    height: 36,
    minWidth: 105
  },

}));

export default ButtonStyle;
