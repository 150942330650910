import Divider from '@material-ui/core/Divider';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect } from 'react';
import { GrNext, GrPrevious } from "react-icons/gr";
import { GetDateWithMyFormat, IsEmpty } from '../../config/appConstant';
import './CustomCalender.css';
import Style from "./CustomCalenderStyle";
import { Images } from '../../config';
import PageSubTitle from '../PageSubTitle/PageSubTitle';
import incandescentlightbulbV2 from '../../../assets/icons/Newicons/incandescent-light-bulb-V2.svg';
import Layericon from '../../../assets/icons/Layericon.png';
import StarIcon from '../../../assets/icons/StarIcon.png';
import { Button } from "../../components";
import moment from 'moment';


const CustomCalendar = props => {
    const DAY = new Date();
    const MonthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    const [MonthData, setWeekDay] = React.useState([{ "ID": 1, "Name": "Sunday" }, { "ID": 2, "Name": "Monday" }, { "ID": 3, "Name": "Tuesday" }, { "ID": 4, "Name": "Wedsday" }, { "ID": 5, "Name": "Thursday" }, { "ID": 6, "Name": "Friday" }, { "ID": 7, "Name": "Saturday" }]);
    const [IsLoading, setIsLoading] = React.useState(true);
    const [CurrentMonth, setCurrentMonth] = React.useState(DAY.getMonth() + 1);
    const [CurrentYear, setCurrentYear] = React.useState(DAY.getFullYear());
    const [CurrentMonthName, setCurrentMonthName] = React.useState(MonthNames[DAY.getMonth()]);
    const [TotalDayOfMonth, setTotalDayOfMonth] = React.useState([]);
    const [TotalCards, setTotalCards] = React.useState([]);
    const [firstDateWeek, setfirstDateWeek] = React.useState(new Date(DAY.getFullYear(), DAY.getMonth(), 1).getDay() + 1);
    const [BlurCards, setBlurCards] = React.useState(0);// for Card Color Blure purpose


    const coptext = "Find your fit. Schedule an appointment today for a trial of Bausch + Lomb contact lenses. Available at: Marietta Eye Clinic Kennestone (760)582-5345"

    useEffect(() => {
        GetCurrntMonth('First')
    }, [])

    const GetCurrntMonth = (val) => {
        setIsLoading(true)
        var pairs = [];
        for (let i = 0; i < new Date(DAY.getFullYear(), DAY.getMonth() + 1, 0).getDate(); i++) {
            pairs.push({ Date: i + 1 });
        }
        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(DAY.getFullYear(), DAY.getMonth(), 1);
        let lastDay = new Date(DAY.getFullYear(), DAY.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx

        for (let i = 0; i < TotalDaysOfMonth; i++) {
            // new Date().getDate() == TotalCards[i].Date
            cards.push({ ID: i + 1, PreviewURL: 'https://cdn.mybauschreach.com/Images/AutomationAssets/30ad2c7-88d2-5f5-83f3-44706c55bfbc_thumb.png?v=1698642914' });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        setTotalDayOfMonth(pairs)
        setIsLoading(false)
        //-------------------------------------------------------------------
        { val != 'First' && props.handleCalendar(GetDateWithMyFormat(firstDay), GetDateWithMyFormat(lastDay)) }//fetch date from Api -Call Api
    }

    const nextMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth + 1)
        var current = ''
        if (currentMonth == 11) {
            current = new Date(date.getFullYear() + 1, -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }
        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //-----------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        // setIsLoading(false)
    }

    const previousMonth = (date, currentMonth, currentYear) => {
        setIsLoading(true)
        setCurrentMonth(currentMonth - 1)
        var current = ''
        if (currentMonth == 1) {
            current = new Date(date.getFullYear(), -1, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        } else {
            current = new Date(date.getFullYear(), currentMonth - 2, 1);
            setfirstDateWeek(current.getDay() + 1)
            setCurrentMonthName(MonthNames[current.getMonth()])
            setCurrentYear(current.getFullYear())
        }

        var daysInMonth = new Date(current.getFullYear(), current.getMonth() + 1, 0).getDate();
        var pairs = [];
        for (let i = 0; i < daysInMonth; i++) {
            pairs.push({ Date: i + 1 });
        }

        //Logic for Get TodalDays Of month from start Day of week to totalday
        var cards = [];
        let firstDay = new Date(current.getFullYear(), current.getMonth(), 1);
        let lastDay = new Date(current.getFullYear(), current.getMonth() + 1, 0);
        let StartDayofWeek = MonthData[firstDay.getDay()].Name
        let Indexxx = MonthData.findIndex(obj => obj.Name === StartDayofWeek);
        let TotalDaysOfMonth = pairs.length + Indexxx
        for (let i = 0; i < TotalDaysOfMonth; i++) {
            cards.push({ ID: i + 1 });
        }
        setTotalCards(cards)
        setBlurCards(Indexxx)
        //--------------------------------------------------------------------
        // props.handleCalendar(GetCMMSDateWithMyFormat(firstDay), GetCMMSDateWithMyFormat(lastDay))//fetch date from Api -Call Api
        setTotalDayOfMonth(pairs)
        props.GetFilterMonthData(current.getMonth() + 1, current.getFullYear())
        setIsLoading(false)
    }

    const OnPressNext = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth < 12) {
            nextMonth(new Date(), CurrentMonth, CurrentYear);
        }
    }
    const OnPressPrevious = () => {
        const currentYear = new Date().getFullYear();
        if (CurrentYear == currentYear && CurrentMonth > 1) {
            previousMonth(new Date(), CurrentMonth, CurrentYear)
        }
    }

    const { classes } = props;
    const d = new Date();
    let name = MonthNames[d.getMonth()];
    return (
        <>
            <div className='customcalender-main' >
                <div className='customcalender-submain' >
                    <div className='customcalender-pagesubtitle' >
                        <PageSubTitle isRightButton2={false} RightButtonText1='Add Post' CenterRightClick={() => OnPressNext(new Date(), CurrentMonth, CurrentYear)} CenterLeftClick={() => OnPressPrevious(new Date(), CurrentMonth, CurrentYear)} CenterContentText={CurrentMonthName + " " + CurrentYear} RightButtonText={'Add to Cart'} LeftData={
                            [{
                                icon: incandescentlightbulbV2,
                                text: 'Tutorial',
                                isDrop: false,
                                ShowTutorial: true
                            },
                            {
                                icon: StarIcon,
                                text: 'Lead Gen Post',
                                isDrop: false,
                                ShowTutorial: true
                            },
                            {
                                icon: Layericon,
                                text: 'Multiple Posts Sched.',
                                isDrop: false,
                                ShowTutorial: true
                            }]} />
                    </div>
                </div>

            </div>
            <div className='customcalender-daynamecontent' >
                <div className='customcalender-dayname' >
                    {MonthData.map((Item,value) => {
                        return (
                            <div key={value} id='CalenderTopTitleContentView-day' className={classes.CalenderTopTitleContentView} >{Item.Name}</div>
                        )
                    })}
                </div>
                <div className='customcalender-date'>
                    {TotalCards.map((Item, Index) => {
                        const calenderContentId = Index < BlurCards ? "calender-content-view-gray" : "calender-content-view-white"
                        return (
                            <div key={Item.id} className={classes.CalenderContentView} id={calenderContentId} >
                                {TotalDayOfMonth.map((item, index) => index == Index - firstDateWeek + 1 &&
                                    <div key={index}>
                                        <div  className='customcalender-mapincss' >
                                            <div className={name == CurrentMonthName && new Date().getDate() == item.Date ? classes.CalenderCurrentContent : classes.CalenderInnerContent}>{item.Date}</div>
                                        </div>
                                        <Divider className='customcalender-divider' />
                                        {props.calenderData.map((Item, ind) => {
                                            let CalenderDate = (moment(item.Date + "/" + CurrentMonthName + "/" + CurrentYear).format("YYYY-MM-DD"))
                                            return (
                                                <div key={ind}>
                                                    {(CalenderDate == Item.DateOfCreation && Item.Assets != '') &&
                                                        <div>
                                                            <div className="content-box-calendar">
                                                                <div className='box-image-calendar calendar-image-box' style={{
                                                                    backgroundImage: `url(${!IsEmpty(Item.Assets[0]) && Item.Assets[0].ImageURL})`
                                                                }}>
                                                                    <div className='button-hover'>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Preview' onClick={() => props.handlePopup(true, "Preview", "View Social Post", { ProductAssetID: Item.Assets[0].CategoryID, Name: Item.Assets[0].Name, ContentData: Item.Assets[0].Description, URL: Item.Assets[0].ImageURL })} /></div>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Edit' onClick={() => props.handlePopup(true, "Edit", "Reschedule Post", { Name: "URL", ContentData: Item.Assets[0].Description, URL: Item.Assets[0].ImageURL })} /></div>
                                                                        <div className='button-field-calendar'><Button round Medium type={3} title='Add Post' onClick={() => props.handlePopup(true, "Add", "Add Social Post", { Name: "URL", ContentData: Item.Assets[0].Description, URL: Item.Assets[0].ImageURL })} /></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>)
                                        })}
                                    </div>
                                )}
                            </div >
                        )
                    })}
                </div>
            </div >
        </>
    )
}

export default withStyles(Style)(CustomCalendar);