import React, { useEffect, useState } from 'react';
import { UploadAssets } from "../../components";
import { IsEmpty, NumberDigitValidation, NumberValidation } from '../../config/appConstant';
import AnynumberInput from '../AnyNumberInput/AnynumberInput';
import Button from '../Button/Button';
import DatePiker from '../DatePicker/DatePiker';
import DateTimePiker from '../DateTimePiker/DateTimePiker';
import Dropdown from '../Dropdown/Dropdown';
import MultipalCheckbox from '../MultipalCheckbox/MultipalCheckbox';
import NumberTextField from '../NumberTextField/NumberTextField';
import RadioButton from '../RadioButton/RadioButton';
import SearchInput from '../SearchInput/SearchInput';
import SingaleCheckBox from '../SingaleCheckBox/SingaleCheckBox';
import CommonSwitch from '../Switch/CommonSwitch';
import TextField from '../TextField/TextField';
import TimePiker from '../TimePiker/TimePiker';
import Editor from '../HtmlEditer/editor';
import './formcantrol.css';


export default function FormCantrol(props) {
    const { FormField, handleOnclick, formEdit, setFormEdit, Formsave, FormEditData, SelectedDropdownID, Closedetails,setSelectedDropdownID, onClose, Description, setDescription, setsearchId, handleClickImage,savedetailsonclick } = props
    const [formvalue, setFormValue] = useState(formEdit ? FormEditData : {});
    const [formName, setFormName] = useState([]);
    const [Error, setError] = useState({});
    const [PhoneNumbervalue, setPhoneNumberValue] = useState('');
    const [searchDefultValue, setSearchDefultValue] = useState("")
    const [DropdownDefultValue, setDropdownDefultValue] = useState({})
    const [refresh, setRefresh] = useState(false)

    // value onChange hadle

    const handleonchange = (e, type, numberdigit, dropdownoption, dropdownID) => {
        const { name, value } = e.target;

        if (type == "MobileNumber" && !numberdigit) {
            if (NumberValidation(e) == "() -") {
                setFormValue({ ...formvalue, [type]: "" });
                setError({ ...Error, [name]: true });
            } else if (NumberValidation(e).length !== 14) {
                setFormValue({ ...formvalue, [type]: NumberValidation(e) });
                setError({ ...Error, [name]: true });
            } else {
                setFormValue({ ...formvalue, [type]: NumberValidation(e) });
                setError({ ...Error, [name]: false });
                setPhoneNumberValue(NumberValidation(e));
            };
        } else if (numberdigit) {

            setFormValue({ ...formvalue, [type]: `${NumberDigitValidation(e, numberdigit)}` });
            setError({ ...Error, [name]: false });

        } else if (type == 'switch') {
            setFormValue({ ...formvalue, [name]: e.target.checked ? 1 : 0, });
            setError({ ...Error, [name]: false });
            setSelectedDropdownID({ ...SelectedDropdownID, [name]: e.target.checked ? 1 : 0, });
        } else if (type == 'dropdown') {
            const dropdownValue = dropdownoption.filter((item) => {
                if (item.value == value) {
                    return item
                }
            });
            setSelectedDropdownID({ ...SelectedDropdownID, [name]: value });
            setFormValue({ ...formvalue, [name]: value, });
            setError({ ...Error, [name]: false });

        }
        else if (type == 'singalcheckbox') {
            setFormValue({ ...formvalue, [name]: e.target.checked ? 1 : 0, });
            setError({ ...Error, [name]: false });
        }
        else {
            setFormValue({ ...formvalue, [name]: value, });
            setError({ ...Error, [name]: false });
        };
    };

    // Search Suggestions value get
    const handleSearchSuggestion = (event, value, fetchSuggestions, fieldName, id = null) => {
        // Update the form value with the Name and ID
        setsearchId(id)
        if(event!=null){
            setRefresh(refresh?false:true)
            setFormValue(prevState => ({
                ...prevState,
                [fieldName]: { Name: value, ID: id }
            }));
        }else{
            setRefresh(refresh?false:true)
            setFormValue(prevState => ({
                ...prevState,
                [fieldName]: { Name: value, ID: id }
            }));
        }

        // Fetch suggestions if needed
        if (fetchSuggestions) {
            fetchSuggestions(value);
        }

        // Optionally handle other logic like validation
        if (value === '') {
            setError(prevState => ({
                ...prevState,
                [fieldName]: true
            }));
        } else {
            setError(prevState => ({
                ...prevState,
                [fieldName]: false
            }));
        }
    };


    // Form Button Handle submit
    const handleFormSubmit = (e, Buttontype) => {
        if (Buttontype == 2) {
            const Errorobject = ErrorLogic();
            if (Errorobject == undefined) {
                handleOnclick(formvalue);
                if (Formsave) {
                    setFormEdit(true);
                    setFormValue({})
                };
                setTimeout(() => {
                    props.onClose();
                }, 200)
            }
        } else if (Buttontype == 3) {
            savedetailsonclick(formvalue)
            setFormValue({})
            setTimeout(() => {
                props.onClose();
            }, 200);
        }
        else if (Buttontype == 4) {
            Closedetails(formvalue)
        }
        else {
            setRefresh(true)
            setFormValue({})
            setTimeout(() => {
                props.onClose();
            }, 200);

        };
    };

    // setfieldname to state
    const fieldNameSet = (FormEditData) => {
        const DefultValueObj = {};
        const Errorobj = {};
        const formFieldName = FormField.map((item, ind) => {
            if (item.Type == "radio") {
                const defaultChaked = item.RadioOption.filter((val) => {
                    if (val.isDefultChecked) {
                        return val.value;
                    };
                });
                DefultValueObj[item.Name] = defaultChaked[0].value;
                return item.Name;
            } else if (item.Type == "search") {
                setSearchDefultValue(item.DefultValue);
                DefultValueObj[item.Name] = item.DefaultValues;
                Errorobj[item.Name] = false;
                return item.Name;

            } else if (item.Type == "switch") {
                DefultValueObj[item.Name] = !item.DefaultValues ? 0 : item.DefaultValues;
                setFormValue({ ...formvalue, [item.Name]: !item.DefaultValues ? 0 : item.DefaultValues })
            }
            else if (item.Type == "singalcheckbox") {
                DefultValueObj[item.Name] = !item.DefaultValues ? 0 : item.DefaultValues;
                setFormValue({ ...formvalue, [item.Name]: !item.DefaultValues ? 0 : item.DefaultValues })
            }
            // } else if (item.Type == "dropdown") {
            //     const defultvalueArry = []
            //     const defaultChaked = item.DropdownOption.map((val) => {

            //         if (val.IsSelect) {
            //             defultvalueArry.push({ [item.Name]: val.value })
            //             return val.value;
            //         };
            //     });
            //     if (defaultChaked != []) {
            //         // setDropdownDefultValue({...DropdownDefultValue,[item.Name]:defaultChaked[0].value});
            //         // // setFormValue({...formvalue,[item.Name]:defaultChaked[0].value})
            //         // DefultValueObj[item.Name] = defaultChaked[0].value;
            //         // Errorobj[item.Name] = false;
            //     } else {
            //         DefultValueObj[item.Name] = '';
            //         Errorobj[item.Name] = true;
            //     }
            else {
                if (item.DefaultValues) {
                    DefultValueObj[item.Name] = item.DefaultValues;
                    Errorobj[item.Name] = false;
                    return item.IsMandatory == 0 ? '' : item.Name;
                } else {
                    DefultValueObj[item.Name] = "";
                    Errorobj[item.Name] = false;
                    return item.IsMandatory == 0 ? '' : item.Name;
                }
            };
        });

        const RemoveLastItem = formFieldName.slice(0, -1);
        const DefultValueName = RemoveLastEle(DefultValueObj);
        const ErrorObj = RemoveLastEle(Errorobj);

        if (formEdit == true) {
            setFormValue(FormEditData);

        } else {
            setFormValue(DefultValueName);
        }
        setFormName(RemoveLastItem);
        setError(ErrorObj);
    };

    // error logic add
    const ErrorLogic = () => {
        const errorchecks = formName.filter((item) => {
            if (IsEmpty(formvalue[item]) && formvalue[item] != '0') {
                return item
            };
        });

        const removeUndefind = errorchecks.filter((item) => {
            if (item != undefined && item != 'Upload' && item != 'ProductFilters' && item != "TagLine") {
                return item;
            };
        });

        if (removeUndefind.length != 0) {
            const errortrue = removeUndefind.map((item, ind) => {
                const Errorobj = {};
                Errorobj[item] = true ? true : false;
                return Errorobj;
            });

            const mergedObject = errortrue.reduce((acc, obj) => {
                return { ...acc, ...obj };
            }, {});
            for (let key in Error) {
                if (mergedObject?.hasOwnProperty(key) && Error[key] !== mergedObject[key]) {
                    setError(prevState => ({
                        ...prevState,
                        [key]: mergedObject[key]
                    }));
                };
            };
            return mergedObject;
        };
    };

    const RemoveLastEle = (Obj) => {
        const entries = Object.entries(Obj);
        entries.pop();
        return Object.fromEntries(entries);
    };


    useEffect(() => {
        fieldNameSet(FormEditData);
    }, []);
    useEffect(() => {

    }, [formEdit]);


    return (

        <div className='form-main'>
            <div className='form-field-main'>
                {FormField.map((item, index) => {

                    if (item.Type == "input") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <TextField error={item.IsMandatory == 1 ? Error[item.Name] : false} IsDisabled={item.IsDisabled} label={item.Label} type={item.Type} IsMandatory={item.IsMandatory} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "dropdown") {

                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <Dropdown error={item.IsMandatory == 1 ? Error[item.Name] : false} id={item.ID} DropdownDefultValue={DropdownDefultValue[item.Name]} label={item.Label} name={item.Name} DropdownOption={item.DropdownOption} value={formvalue[item.Name] == undefined ? "" : formvalue[item.Name]} onChange={(e) => handleonchange(e, item.Type, '', item.DropdownOption, item.ID)} />

                            </div>
                        )
                    } else if (item.Type == "radio") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <RadioButton RadioOption={item.RadioOption} lable={item.Label} value={formvalue[item.Name]} name={item.Name} formvalue={formvalue} setFormValue={setFormValue} />
                            </div>
                        )
                    } else if (item.Type == "MobileNumber") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <NumberTextField error={item.IsMandatory == 1 ? Error[item.Name] : false} formvalue={formvalue} setFormValue={setFormValue} label={item.Label} name={item.Name} value={formvalue[item.Name]} PhoneNumbervalue={PhoneNumbervalue} onChange={(e) => handleonchange(e, item.Name)} />
                            </div>
                        )
                    } else if (item.Type == "pincode") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <AnynumberInput error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} maxLength={item.maxLength} value={formvalue[item.Name]} onChange={(e) => handleonchange(e, item.Name, item.maxLength)} />
                            </div>
                        )
                    } else if (item.Type == "number") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <AnynumberInput error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} maxLength={item.MaxLength ? item.MaxLength : 1000000} value={`${formvalue[item.Name]}`} onChange={(e) => handleonchange(e, item.Name, 100000000)} />
                            </div>
                        )
                    } else if (item.Type == "price") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                {/* <AnynumberInput error={item.IsMandatory ? Error[item.Name] : false} label={item.Label} name={item.Name} maxLength={1000000} value={formvalue[item.Name]} onChange={(e) => handleonchange(e, item.Name, 100000000)} /> */}
                            </div>
                        )
                    } else if (item.Type == "date") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <DatePiker error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "time") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <TimePiker error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} type={item.Type} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "datetime-local") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <DateTimePiker error={item.IsMandatory == 1 ? Error[item.Name] : false} label={item.Label} name={item.Name} value={formvalue[item.Name]} onChange={(e) => handleonchange(e)} />
                            </div>
                        )
                    } else if (item.Type == "switch") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <CommonSwitch label={item.Label} name={item.Name} value={formvalue[item.Name] == 1 ? true : false} checked={formvalue[item.Name] == 1 ? true : false} onChange={(e) => handleonchange(e, item.Type, '', '',)} />
                            </div>
                        )
                    } else if (item.Type == "search") {
                        console.log('item?.Name:', item?.Name)
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <SearchInput error={item.IsMandatory == 1 ? Error[item.Name] : false} refresh={refresh} editvalue={formEdit ? formvalue[item?.Name]||formvalue[item?.Name]?.ID : ''} searchDefultValue={searchDefultValue} label={item.Label} name={item.Name} SearchSuggestion={item.SearchSuggestion} value={formvalue[item?.Name]?.Name || ''}
                                    onInputChange={(event, value, fetchSuggestions, id) =>
                                        handleSearchSuggestion(event, value, fetchSuggestions, item.Name, id)
                                    } />
                                {/* onInputChange={(event, value, fetchSuggestions) => 
                                    handleSearchSuggestion(event, value, fetchSuggestions, item.Name, item.SearchSuggestion)} /> */}
                            </div>
                        )
                    } else if (item.Type == "checkbox") {

                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <MultipalCheckbox error={item.IsMandatory == 1 ? Error[item.Name] : false} formEdit={formEdit} name={item.Name} lable={item.Label} value={item.checkboxOption} formvalue={formvalue} setFormValue={setFormValue} />
                            </div>
                        )
                    } else if (item.Type == "singalcheckbox") {
                        return (
                            <div key={index} className="form-field-fullwidth">
                                <SingaleCheckBox error={item.IsMandatory == 1 ? Error[item.Name] : false} id={item.Name} label={item.Label} name={item.Name} checked={formvalue[item.Name] == 1 ? true : false} onChange={(e) => handleonchange(e, item.Type, '', '',)} />
                            </div>
                        );
                    }
                    else if (item.Type == "uploadAssets") {
                        return (
                            <div key={index} className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <UploadAssets isMultipleAdded={item.isMultipleAdded} ObjectID={item.ObjectID} IsEdit={item.IsEdit} Label={item.Label} IsSavedClick={item.IsSavedClick} DocumentTypeID={item.DocumentTypeID} handleClickImage={handleClickImage} onClose={onClose} />
                            </div>
                        )
                    }
                    else if (item.Type == "HtmlEditer") {
                        return (
                            <div key={index} id="htmlediter-main" className={item.IsFullWidth != true ? "form-field-fullwidth" : "form-field-hafewidth"}>
                                <Editor content={Description} setContent={setDescription} />
                            </div>
                        )
                    } else if (item.Type == "button") {
                        return (
                            <div key={index} className='form-button'>
                                {item.Button.map((val, ind) => {
                                    return (
                                        <div key={ind} className='from-button-inner'>
                                            <Button type={val.Buttontype == 1 ? 4 : val.Buttontype} onClick={(e) => handleFormSubmit(e, val.Buttontype)} title={val.lable} />
                                        </div>
                                    )
                                })}
                            </div>
                        )
                    }
                })}
            </div>
        </div>

    )
}

