import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Circle, GoogleMap, Marker, withGoogleMap, withScriptjs } from "react-google-maps";
import { compose, withProps } from "recompose";
import { URLs } from "../../API/URLs";
import { IsEmpty } from "../../config/appConstant";

const MyMapComponent = compose(withProps({
	googleMapURL: "https://maps.googleapis.com/maps/api/js?key=" + URLs.googlekey + "&v=3.exp&libraries=geometry,drawing,places",
	loadingElement: <div style={{ height: `100%` }} />,
	containerElement: <div style={{ height: `100%` }} />,
	mapElement: <div style={{ height: `100%` }} />,
}), withScriptjs, withGoogleMap)((props) => {
	const { isMarkerShown, MapOptions, zoom, isRadiusShow, locationDataSet, locationRadius } = props
	return (
		<GoogleMap
			options={MapOptions}
			zoom={zoom}
			center={!IsEmpty(locationDataSet) ? locationDataSet[0] : ''}>
			{isMarkerShown && locationDataSet.map((item, index) => {
				return (
					<Fragment key={index}>
						<Marker
							icon={locationDataSet.length - 1 == index ? "http://maps.google.com/mapfiles/ms/icons/blue-dot.png" : ""}
							position={{
								lat: item.lat,
								lng: item.lng
							}}
						/>
						{isRadiusShow && <Circle
							center={{
								lat: item.lat,
								lng: item.lng
							}}
							radius={parseInt(locationRadius) + 2000}
							visible={true}
							options={{ strokeColor: "#ff0000" }}
						/>}
					</Fragment>
				);
			})}

		</GoogleMap>
	)
});
MyMapComponent.propTypes = {
	isMarkerShown: PropTypes.bool,
	isRadiusShow: PropTypes.bool,
	locationRadius: PropTypes.number,
	zoom: PropTypes.number,
	currentCityCord: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	MapOptions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	locationDataSet: PropTypes.array,
};

MyMapComponent.defaultProps = {
	currentCityCord: {},
	locationDataSet: [],
	isMarkerShown: false,
	isRadiusShow: false,
	MapOptions: { streetViewControl: false },
	locationRadius: 5 * 1000,
	zoom: 10
};
export default MyMapComponent;
