import { URLs } from './URLs'
import { API, HTTPMethod } from "../API/API";

export const Login = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + '/token', JSON.stringify(objParameter));
export const GetMySubscriptions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMySubscriptions', JSON.stringify(objParameter));
export const GetNav = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/ACS/GetNav', JSON.stringify(objParameter));



// Change password
export const ChangePassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/ChangePassword', JSON.stringify(objParameter));

// ForgotPassword
export const ForgotPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/User/ResetPassword', JSON.stringify(objParameter));

// Admin Side start
//Users
export const GetUsers = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUsers', JSON.stringify(objParameter));
export const GetUserByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserByID', JSON.stringify(objParameter));
export const DeleteUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/DeleteUser', JSON.stringify(objParameter));
export const AddEditUser = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/AddEditUser', JSON.stringify(objParameter));
export const GetUserForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetUserForDropdown', JSON.stringify(objParameter));

export const SetPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/SetPassword', JSON.stringify(objParameter));
// export const ResetPassword = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/ResetPassword', JSON.stringify(objParameter));
export const EditProfile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/EditProfile', JSON.stringify(objParameter));
export const GetMyProfile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/User/GetMyProfile', JSON.stringify(objParameter));


//ACS Start

//Rights
export const GetRights = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRights', JSON.stringify(objParameter));
export const GetRightForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRightForDropdown', JSON.stringify(objParameter));
export const GetRightByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRightByID', JSON.stringify(objParameter));
export const DeleteRight = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/DeleteRight', JSON.stringify(objParameter));
export const AddEditRight = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/AddEditRight', JSON.stringify(objParameter));

//Roles
export const GetRoles = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRoles', JSON.stringify(objParameter));
export const GetRoleForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRoleForDropdown', JSON.stringify(objParameter));
export const GetRoleByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetRoleByID', JSON.stringify(objParameter));
export const DeleteRole = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/DeleteRole', JSON.stringify(objParameter));
export const AddEditRole = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/AddEditRole', JSON.stringify(objParameter));

//Screens
export const GetScreens = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreens', JSON.stringify(objParameter));
export const GetScreenForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreenForDropdown', JSON.stringify(objParameter));
export const GetScreenByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreenByID', JSON.stringify(objParameter));
export const DeleteScreen = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/DeleteScreen', JSON.stringify(objParameter));
export const AddEditScreen = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/AddEditScreen', JSON.stringify(objParameter));
export const ScreenForDropdownIsParent = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/ACS/GetScreenForDropdown', JSON.stringify(objParameter));

//ACS end

//Communication

export const GetCommunications = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Communication/GetCommunications', JSON.stringify(objParameter));
export const DownloadCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DownloadCommunicationTemplate', JSON.stringify(objParameter));
export const SetCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Communication/SetCommunicationTemplate', JSON.stringify(objParameter));

export const AddEditCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/AddEditCommunicationConfiguration', JSON.stringify(objParameter));
export const DeleteCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DeleteCommunicationConfiguration', JSON.stringify(objParameter));
export const GetCommunicationConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurationByID', JSON.stringify(objParameter));
export const GetCommunicationConfigurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurations', JSON.stringify(objParameter));
export const GetCommunicationConfigurationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurationForDropdown', JSON.stringify(objParameter));

//CommunicationTemplates
export const GetCommunicationTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplates', JSON.stringify(objParameter));
export const AddEditCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/AddEditCommunicationTemplate', JSON.stringify(objParameter));
export const DeleteCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DeleteCommunicationTemplate', JSON.stringify(objParameter));
export const GetCommunicationTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplateByID', JSON.stringify(objParameter));
export const GetCommunicationTemplateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplateForDropdown', JSON.stringify(objParameter));

//Tenants

//Tenants
export const GetTenants = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenants', JSON.stringify(objParameter));
export const AddEditTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenant', JSON.stringify(objParameter));
export const DeleteTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenant', JSON.stringify(objParameter));
export const GetTenantByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantByID', JSON.stringify(objParameter));
export const GetTenantForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantForDropdown', JSON.stringify(objParameter));

//TenantsDomain
export const GetTenantDomains = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomains', JSON.stringify(objParameter));
export const AddEditTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenantDomain', JSON.stringify(objParameter));
export const DeleteTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenantDomain', JSON.stringify(objParameter));
export const GetTenantDomainByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomainByID', JSON.stringify(objParameter));
export const GetTenantDomainForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomainForDropdown', JSON.stringify(objParameter));


//TenantSMTP
export const GetTenantSMTPForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTPForDropdown', JSON.stringify(objParameter));
export const GetTenantSMTPByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTPByID', JSON.stringify(objParameter));
export const GetTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTP', JSON.stringify(objParameter));
export const DeleteTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenantSMTP', JSON.stringify(objParameter));
export const AddEditTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenantSMTP', JSON.stringify(objParameter));




//Master
//Material Type
export const GetMaterialTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMaterialTypes', JSON.stringify(objParameter));
export const GetMaterialTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetMaterialTypeForDropdown', JSON.stringify(objParameter));
export const GetMaterialTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMaterialTypeByID', JSON.stringify(objParameter));
export const DeleteMaterialType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteMaterialType', JSON.stringify(objParameter));
export const AddEditMaterialType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditMaterialType', JSON.stringify(objParameter));

// TestTypes
export const GetTestTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTestTypes', JSON.stringify(objParameter));
export const GetTestTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTestTypeForDropdown', JSON.stringify(objParameter));
export const GetTestTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTestTypeByID', JSON.stringify(objParameter));
export const DeleteTestType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteTestType', JSON.stringify(objParameter));
export const AddEditTestType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditTestType', JSON.stringify(objParameter));
// Moldings
export const GetMoldings = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMoldings', JSON.stringify(objParameter));
export const GetMoldingForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMoldingForDropdown', JSON.stringify(objParameter));
export const GetMoldingByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMoldingByID', JSON.stringify(objParameter));
export const DeleteMolding = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteMolding', JSON.stringify(objParameter));
export const AddEditMolding = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditMolding', JSON.stringify(objParameter));

// PolymerTypes
export const GetPolymerTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPolymerTypes', JSON.stringify(objParameter));
export const GetPolymerTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPolymerTypeForDropdown', JSON.stringify(objParameter));
export const GetPolymerTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPolymerTypeByID', JSON.stringify(objParameter));
export const DeletePolymerType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeletePolymerType', JSON.stringify(objParameter));
export const AddEditPolymerType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditPolymerType', JSON.stringify(objParameter));

// Properties
export const GetProperties = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetProperties', JSON.stringify(objParameter));
export const GetPropertyForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPropertyForDropdown', JSON.stringify(objParameter));
export const GetPropertyByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetPropertyByID', JSON.stringify(objParameter));
export const DeleteProperty = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteProperty', JSON.stringify(objParameter));
export const AddEditProperty = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditProperty', JSON.stringify(objParameter));

// BatchType
export const GetBatchTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetBatchTypes', JSON.stringify(objParameter));
export const GetBatchTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetBatchTypeForDropdown', JSON.stringify(objParameter));
export const GetBatchTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetBatchTypeByID', JSON.stringify(objParameter));
export const DeleteBatchType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteBatchType', JSON.stringify(objParameter));
export const AddEditBatchType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditBatchType', JSON.stringify(objParameter));

// Manufacturers
export const GetManufacturers = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetManufacturers', JSON.stringify(objParameter));
export const GetManufacturerForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetManufacturerForDropdown', JSON.stringify(objParameter));
export const GetManufacturerByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetManufacturerByID', JSON.stringify(objParameter));
export const DeleteManufacturer = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteManufacturer', JSON.stringify(objParameter));
export const AddEditManufacturer = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditManufacturer', JSON.stringify(objParameter));

//Materials
export const GetMaterials = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Material/GetMaterials', JSON.stringify(objParameter));
export const GetMaterialForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Material/GetMaterialForDropdown    ', JSON.stringify(objParameter));
export const GetMaterialByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialByID', JSON.stringify(objParameter));
export const DeleteMaterial = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/DeleteMaterial', JSON.stringify(objParameter));
export const AddEditMaterial = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/AddEditMaterial', JSON.stringify(objParameter));

//MaterialPriceChangeHistory
export const GetMaterialPriceChangeHistory = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialPriceChangeHistory', JSON.stringify(objParameter));

//MaterialTypeTestTypes
export const GetMaterialTypeTestTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMaterialTypeTestTypes', JSON.stringify(objParameter));
export const GetMaterialTypeTestTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMaterialTypeTestTypeForDropdown', JSON.stringify(objParameter));
export const GetMaterialTypeTestTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetMaterialTypeTestTypeByID', JSON.stringify(objParameter));
export const DeleteMaterialTypeTestType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteMaterialTypeTestType', JSON.stringify(objParameter));
export const AddEditMaterialTypeTestType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditMaterialTypeTestType', JSON.stringify(objParameter));


//MaterialInspection
export const GetMaterialInspectionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialInspectionByID', JSON.stringify(objParameter));
export const GetMaterialInspection = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialInspection', JSON.stringify(objParameter));
export const DeleteMaterialInspection = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/DeleteMaterialInspection', JSON.stringify(objParameter));
export const AddEditMaterialInspection = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/AddEditMaterialInspection', JSON.stringify(objParameter));

// // Communication

// // Communication
// export const GetCommunications = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + '/API/Misc/GetCommunications', JSON.stringify(objParameter));
// export const DownloadCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DownloadCommunicationTemplate', JSON.stringify(objParameter));


// // CommunicationConfiguration
// export const AddEditCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/AddEditCommunicationConfiguration', JSON.stringify(objParameter));
// export const DeleteCommunicationConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DeleteCommunicationConfiguration', JSON.stringify(objParameter));
// export const GetCommunicationConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurationByID', JSON.stringify(objParameter));
// export const GetCommunicationConfigurations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurations', JSON.stringify(objParameter));
// export const GetCommunicationConfigurationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationConfigurationForDropdown', JSON.stringify(objParameter));

// //CommunicationTemplates
// export const GetCommunicationTemplates = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplates', JSON.stringify(objParameter));
// export const AddEditCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/AddEditCommunicationTemplate', JSON.stringify(objParameter));
// export const DeleteCommunicationTemplate = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/DeleteCommunicationTemplate', JSON.stringify(objParameter));
// export const GetCommunicationTemplateByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplateByID', JSON.stringify(objParameter));
// export const GetCommunicationTemplateForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Communication/GetCommunicationTemplateForDropdown', JSON.stringify(objParameter));

// // Tenant
// //Tenants
// export const GetTenants = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenants', JSON.stringify(objParameter));
// export const AddEditTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenant', JSON.stringify(objParameter));
// export const DeleteTenant = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenant', JSON.stringify(objParameter));
// export const GetTenantByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantByID', JSON.stringify(objParameter));
// export const GetTenantForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantForDropdown', JSON.stringify(objParameter));

// //TenantsDomain
// export const GetTenantDomains = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomains', JSON.stringify(objParameter));
// export const AddEditTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenantDomain', JSON.stringify(objParameter));
// export const DeleteTenantDomain = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenantDomain', JSON.stringify(objParameter));
// export const GetTenantDomainByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomainByID', JSON.stringify(objParameter));
// export const GetTenantDomainForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantDomainForDropdown', JSON.stringify(objParameter));

// //TenantSMTP
// export const GetTenantSMTPForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTPForDropdown', JSON.stringify(objParameter));
// export const GetTenantSMTPByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTPByID', JSON.stringify(objParameter));
// export const GetTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/GetTenantSMTP', JSON.stringify(objParameter));
// export const DeleteTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/DeleteTenantSMTP', JSON.stringify(objParameter));
// export const AddEditTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Tenant/AddEditTenantSMTP', JSON.stringify(objParameter));

//Tenants Configuration
export const AddEditTenantConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Tenant/AddEditTenantConfiguration', JSON.stringify(objParameter));
export const GetTenantConfigurationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Tenant/GetTenantConfigurationByID', JSON.stringify(objParameter));
export const GetTenantConfiguration = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Tenant/GetTenantConfiguration', JSON.stringify(objParameter));

// APILogs
export const GetAPILogs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetAPILogs', JSON.stringify(objParameter));

// //Documents
// export const GetDocuments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocuments', JSON.stringify(objParameter));
// export const GetDocumentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocumentByID', JSON.stringify(objParameter));
// export const DeleteDocument = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/DeleteDocument', JSON.stringify(objParameter));
// export const AddEditDocument = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/AddEditDocument', JSON.stringify(objParameter));
// export const GetDocumentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocumentForDropdown', JSON.stringify(objParameter)); 

//DocumentTypes
export const GetDocumentTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentTypes', JSON.stringify(objParameter));
export const GetDocumentTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentTypeForDropdown', JSON.stringify(objParameter));
export const GetDocumentTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentTypeByID', JSON.stringify(objParameter));
export const DeleteDocumentType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteDocumentType', JSON.stringify(objParameter));
export const AddEditDocumentType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditDocumentType', JSON.stringify(objParameter));


//GetBugTracker
export const GetBugTracker = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BugTracker/GetBugTracker', JSON.stringify(objParameter));
export const ResolveBug = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BugTracker/ResolveBug', JSON.stringify(objParameter));
export const GetBuyByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/BugTracker/GetBuyByID', JSON.stringify(objParameter));


// //TenantSMTP
// export const GetTenantSMTPForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTenantSMTPForDropdown', JSON.stringify(objParameter));
// export const GetTenantSMTPByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTenantSMTPByID', JSON.stringify(objParameter));
// export const GetTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetTenantSMTP', JSON.stringify(objParameter));
// export const DeleteTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteTenantSMTP', JSON.stringify(objParameter));
// export const AddEditTenantSMTP = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/AddEditTenantSMTP', JSON.stringify(objParameter));

//Documents
export const GetDocuments = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocuments', JSON.stringify(objParameter));
export const GetDocumentByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentByID', JSON.stringify(objParameter));
export const DeleteDocument = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/DeleteDocument', JSON.stringify(objParameter));
export const GetDocumentForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Master/GetDocumentForDropdown', JSON.stringify(objParameter));

// Admin side End

// Frontend Side APIs
export const GetHome = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetHome', JSON.stringify(objParameter));
export const GetHomeDetails = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetHomeDetails', JSON.stringify(objParameter));
export const GetHomeSubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetHomeSubCategories', JSON.stringify(objParameter));
export const GetActionLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetLocations', JSON.stringify(objParameter));
export const GetActionLocationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/GetLocationByID', JSON.stringify(objParameter));
export const GetAdBuilderAssets = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetAdBuilderAssets', JSON.stringify(objParameter));
export const UpdateLocation_Featured_Location = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/UpdateLocation_Featured_Location', JSON.stringify(objParameter));
export const UpdateLocation_Location = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/UpdateLocation_Location', JSON.stringify(objParameter));
export const UpdateLocation_SocialAutomation_SelectAll = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Action/UpdateLocation_SocialAutomation_SelectAll', JSON.stringify(objParameter));
export const GetCategorySubCategories = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetCategorySubCategories', JSON.stringify(objParameter));

// promowebsite APIs
export const GetMyPromoWebsite = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMyPromoWebsite', JSON.stringify(objParameter));
// Cart
export const GetCart = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetCart', JSON.stringify(objParameter));
// CalculatePostageCharges
export const CalculatePostageCharges = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/CalculatePostageCharges', JSON.stringify(objParameter));

// Calendar
export const GetMyCalendar = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetMyCalendar', JSON.stringify(objParameter));
export const GeneratePostCopy = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GeneratePostCopy', JSON.stringify(objParameter));

//Download File 
export const GetDownloadFile = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/DownloadFile', JSON.stringify(objParameter));
export const GetProductAssetsForSocialAutomation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Action/GetProductAssetsForSocialAutomation', JSON.stringify(objParameter));

// ProductFreeAsset
export const GetProductFreeAssetByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/GetProductFreeAssetByID', JSON.stringify(objParameter));// Asssets
export const DeleteProductFreeAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/DeleteProductFreeAsset', JSON.stringify(objParameter));// Asssets
export const AddEditProductFreeAsset = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/AddEditProductFreeAsset', JSON.stringify(objParameter));// Asssets
export const GetProductFreeAsses = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Asset/GetProductFreeAsses', JSON.stringify(objParameter));// Asssets

//GlobalModuleTabs
export const GetGlobalModuleTabs = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleTabs', JSON.stringify(objParameter));
export const GetGlobalModuleTabForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleTabForDropdown', JSON.stringify(objParameter));
export const GetGlobalModuleTabByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/GetGlobalModuleTabByID', JSON.stringify(objParameter));
export const DeleteGlobalModuleTab = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/DeleteGlobalModuleTab', JSON.stringify(objParameter));
export const AddEditGlobalModuleTab = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Global/AddEditGlobalModuleTab', JSON.stringify(objParameter));

// Media Library 
export const GetDocumentsAsLibrary = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetDocumentsAsLibrary', JSON.stringify(objParameter));
export const UpdateDocumentDescription = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/UpdateDocumentDescription', JSON.stringify(objParameter));
export const SetDocumentAsFavourite = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/SetDocumentAsFavourite', JSON.stringify(objParameter));

// Compounds
// Compounds
export const GetCompounds = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompounds', JSON.stringify(objParameter))
export const GetCompoundByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundByID', JSON.stringify(objParameter));
export const DeleteCompound = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/DeleteCompound', JSON.stringify(objParameter));
export const AddEditCompound = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/AddEditCompound', JSON.stringify(objParameter));

// CompoundMaterial
export const GetCompoundMaterials = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundMaterials', JSON.stringify(objParameter));
export const GetCompoundMaterialByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundMaterialByID', JSON.stringify(objParameter));
export const DeleteCompoundMaterial = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/DeleteCompoundMaterial', JSON.stringify(objParameter));
export const AddEditCompoundMaterial = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/AddEditCompoundMaterial', JSON.stringify(objParameter));

// CompoundProperty
export const GetCompoundProperties = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundProperties', JSON.stringify(objParameter));
export const GetCompoundPropertyByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundPropertyByID', JSON.stringify(objParameter));
export const DeleteCompoundProperty = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/DeleteCompoundProperty', JSON.stringify(objParameter));
export const AddEditCompoundProperty = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/AddEditCompoundProperty', JSON.stringify(objParameter));

// Compound Mixing Instructions
export const GetCompoundMixingInstructions = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundMixingInstructions', JSON.stringify(objParameter));
export const GetCompoundMixingInstructionByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundMixingInstructionByID', JSON.stringify(objParameter));
export const DeleteCompoundMixingInstruction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/DeleteCompoundMixingInstruction', JSON.stringify(objParameter));
export const AddEditCompoundMixingInstruction = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/AddEditCompoundMixingInstruction', JSON.stringify(objParameter));

// Compound Moldings
export const GetCompoundMoldings = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundMoldings', JSON.stringify(objParameter));
export const GetCompoundMoldingByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/GetCompoundMoldingByID', JSON.stringify(objParameter));
export const DeleteCompoundMolding = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/DeleteCompoundMolding', JSON.stringify(objParameter));
export const AddEditCompoundMolding = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Compound/AddEditCompoundMolding', JSON.stringify(objParameter));
export const CreateNewRevision = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Compound/CreateNewRevision', JSON.stringify(objParameter));

// Locations

export const GetLocations = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetLocations', JSON.stringify(objParameter));
export const GetLocationByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetLocationByID', JSON.stringify(objParameter));
export const DeleteLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/DeleteLocation', JSON.stringify(objParameter));
export const AddEditLocation = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/AddEditLocation', JSON.stringify(objParameter));
export const GetLocationForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetLocationForDropdown', JSON.stringify(objParameter));

//Material Price Units

export const GetMaterialPriceUnits = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetMaterialPriceUnits', JSON.stringify(objParameter));
export const GetMaterialPriceUnitByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetMaterialPriceUnitByID', JSON.stringify(objParameter));
export const DeleteMaterialPriceUnit = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/DeleteMaterialPriceUnit', JSON.stringify(objParameter));
export const AddEditMaterialPriceUnit = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/AddEditMaterialPriceUnit', JSON.stringify(objParameter));
export const GetMaterialPriceUnitForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'api/Master/GetMaterialPriceUnitForDropdown', JSON.stringify(objParameter));

// Material Test Type

export const GetMaterialTestTypes = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialTestTypes', JSON.stringify(objParameter));
export const GetMaterialTestTypeForDropdown = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialTestTypeForDropdown', JSON.stringify(objParameter));
export const GetMaterialTestTypeByID = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/GetMaterialTestTypeByID', JSON.stringify(objParameter));
export const DeleteMaterialTestType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/DeleteMaterialTestType', JSON.stringify(objParameter));
export const AddEditMaterialTestType = (objParameter) => API.shared.request(HTTPMethod.post, URLs.base + 'API/Material/AddEditMaterialTestType', JSON.stringify(objParameter));
